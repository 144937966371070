<template>
  <el-container>
    <el-header>
      <el-row :span="24" :gutter="10">
        <el-col :span="3">
          <div class="logo_image">
            <el-image
              :src="require('../public/logo.png')"
              fit="scale-down"
              style="width: 150px; height: 70px"
            ></el-image>
          </div>
        </el-col>
        <el-col :span="16">
          <common-aside></common-aside>
        </el-col>

        <el-col :span="4">
          <el-col :span="24">
            <div class="iclimate_info">武汉克莱美特环境设备有限公司</div>
          </el-col>
          <el-col :span="24">
            <div class="iclimate_addr">
              <el-link
                type="success"
                href="http://www.iclimate.top"
                target="_blank"
                >www.whclimate com</el-link
              >
            </div>
          </el-col>
        </el-col>
        <el-col :span="1">
          <el-avatar
            src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
          ></el-avatar>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link"
              ><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">账户信息</el-dropdown-item>
              <el-dropdown-item command="b">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </el-header>
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer style="background-color: #eeeeee">
        <div class="s-bottom-layer-content">
          <p class="lh">
            <a class="text-color" href="https://beian.miit.gov.cn">鄂ICP备2022003227号-1</a>
          </p>

          <p class="lh">
            <a class="text-color" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010602004673">鄂公网安备 42010602004673号</a>
          </p>
        </div>
      </el-footer>
    </el-container>

    <el-dialog title="用户信息" :visible.sync="dialogTableVisible" center>
      <div class="mytable">
        <el-table
          :data="gridData"
          style="width: 100%"
          border
          highlight-current-row
        >
          <el-table-column
            align="center"
            header-align="center"
            property="usrname"
            label="用户名"
          ></el-table-column>
          <el-table-column
            align="center"
            header-align="center"
            property="company"
            label="客户名称"
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import CommonAside from "../src/components/CommonAside.vue";

export default {
  components: {
    CommonAside,
  },
  name: "Main",
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogTableVisible: false,
      gridData: [],
      websocket: null,
      commonPath: 'wss://www.iclimate.top:8888/ws/',
      title: '202207007',
      fullPath: ''
    };
  },
  mounted() {
    this.$router.push("/normal");
    this.init();
  },
  destroyed() {
    // 销毁监听
    this.websocket.close()
  },
  methods: {
    logout() {
      this.$store.commit("clearToken");
      this.$store.commit("clearUSRName");
      this.$store.commit("clearUSRPsw");
      this.$store.commit("clearCompany");
      this.$store.commit("clearTitleList");
      this.$store.commit("clearTitleNo");
      this.$router.push({ name: "login" });
    },
    handleCommand(command) {
      if (command === "b") {
        this.logout();
      }

      if (command === "a") {
        const usrname = this.$store.state.user.usrname;
        const company = this.$store.state.user.company;

        this.gridData = [];

        let obj = {};
        obj.usrname = usrname;
        obj.company = company;
        this.gridData.push(obj);
        this.dialogTableVisible = true;
      }
    },
    init() {
      try {
        if (typeof WebSocket === 'undefined') {
          this.$notify({
            title: '警告',
            message: '您的浏览器不支持websocket！',
            type: 'warning'
          })
        } else {
          // 实例化websocket
          this.fullPath = this.commonPath + this.title
          this.websocket = new WebSocket(this.fullPath)
          // 监听websocket连接
          this.websocket.onopen = this.onopen
          // 监听websocket错误信息
          this.websocket.onerror = this.onerror
          // 监听websocket消息
          this.websocket.onmessage = this.onMessage
          // 监听websocket关闭
          this.websocket.onclose = this.onclose
          //this.websocketSend()
        }
      } catch (error) {
        console.log(error)
      }
    },
    onopen() {
      console.log('websocket连接成功')
    },
    onerror() {
      console.log('连接错误')
      this.reconnect()
    },
    onMessage(e) {
      console.log(e.data)
    },
    onclose() {
      console.log('websocket已经关闭')
      this.reconnect()
    },
    websocketSend(text) {
      try {
        setTimeout(() => {
          this.websocket.send(text)
        }, 3000)
      } catch (err) {
        console.log('send failed (' + err.code + ')')
      }
    },
    reconnect() {
      var that = this
      if (that.lockReconnect) return
      that.lockReconnect = true
      // 没连接上会一直重连，设置延迟避免请求过多
      setTimeout(() => {
        console.info('尝试重连...')
        that.init()
        that.lockReconnect = false
      }, 5000)
    }
  },
};
</script>

<style lang="scss" scoped>
// el-container {
//   margin: 0;
//   padding: 0;
// }

el-header,
el-row,
el-col {
  margin: 0;
  padding: 0;
}



.iclimate_addr {
  margin-top: 0.26vw;
  margin-bottom: 0.26vw;
  font-size: 0.625vw;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.iclimate_info {
  margin-top: 1.26vw;
  margin-bottom: 0.26vw;
  font-size: 14px;
  font-weight: 700;
  text-shadow: 5px 5px 6px rgba(99, 30, 188, 0.6);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.logo_image {
  width: 100%;
  height: 100%;
  // display: flex;
  align-items: center;
  justify-content: center;
  .el-image {
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    border-radius: 30%;
  }
}

.mytable ::v-deep {
  .el-table .el-table__header-wrapper tr th {
    background-color: rgb(18, 47, 92) !important;
    color: rgb(255, 255, 255);
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: normal;
  }
  .el-table .el-table__row {
    background-color: rgb(18, 47, 92);
    color: rgb(255, 255, 255);
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: normal;
  }

  .el-table .el-table__body td {
    background-color: lightblue !important;
    color: #3a3a3a;
  }
  .el-table .el-table__body tr:hover > td {
    background-color: lightgreen !important;
    color: red;
  }

  .el-table__header tr,
  .el-table__header th {
    height: 2.083vw;
    padding: 0;
  }
  .el-table__body tr,
  .el-table__body td {
    height: 2.083vw;
    padding: 0;
  }

  .el-table {
    margin-top: 0.521vw;
  }

  .el-table th {
    display: table-cell !important;
  }

  .el-table--border::after,
  .el-table--group::after {
    width: 0;
  }
  .el-table::before {
    height: 0;
  }
}
</style>
