<template>
  <el-menu default-active="/normal" class="el-menu-demo" mode="horizontal" active-text-color="#009C9C"
    @open="handleOpen" @close="handleClose">
    <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :index="item.path" :key="item.path">
      <i :class="'el-icon-' + item.icon"></i>
      <span slot="title">{{item.label}}</span>
    </el-menu-item>
    <el-submenu v-for="item in hasChildren" :index="item.path" :key="item.path">
      <template slot="title">
        <i :class="'el-icon-' + item.icon"></i>
        <span slot="title">{{item.label}}</span>
      </template>
      <el-menu-item-group v-for="(subItem, subIndex) in item.children" :key="subItem.path">
        <el-menu-item @click="clickMenu(subItem)" :index="subIndex">{{subItem.label}}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<style scoped>
</style>

<script>
export default {
  data () {
    return {
      menu: [
        {
          path: '/normal',
          name: 'normal',
          label: '公司位置',
          icon: 'location',
          url: 'normal/normal'
        },
        {
          path: '/detail',
          name: 'detail',
          label: '运行信息',
          icon: 's-help',
          url: 'detail/detail'
        },
        {
          path: '/curve',
          name: 'curve',
          label: '历史曲线',
          icon: 'pie-chart',
          url: 'curve/curve'
        },
        {
          path: '/statistic',
          name: 'statistic',
          label: '使用统计',
          icon: 's-promotion',
          url: 'statistic/statistic'
        },
        {
          path: '/historyalarm',
          name: 'historyalarm',
          label: '历史报警',
          icon: 'discover',
          url: 'historyalarm/historyalarm'
        },
        {
          path: '/program',
          name: 'program',
          label: '程序信息',
          icon: 'edit-outline',
          url: 'program/program'
        },

      ]
    };
  },
  mounted () {
    this.showHome()
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath);
    },
    clickMenu (item) {
      console.log(item)
      this.$router.push({
        name: item.name
      })
      //this.$store.commit('selectMenu', item)
    },
    showHome () {
      this.$router.push({
        name: "home"
      })
    }
  },
  computed: {
    noChildren () {
      return this.menu.filter(item => !item.children)
    },
    hasChildren () {
      return this.menu.filter(item => item.children)
    },
    // isCollapse() {
    //    return this.$store.state.tab.isCollapse
    // }
  }
};
</script>

<style lang='scss' scoped>

.el-menu {
    border-bottom: solid 1px gray;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 20px;
    background-color: #FFF;
}
.el-menu--horizontal>.el-menu-item {
    float: left;
    height: 60px;
    line-height: 60px;
    margin: 0 15px auto;
    background-color: aquamarine;
    border-bottom: 2px solid red;
    color: red;
    border-radius: 20px;
}

.el-menu--horizontal>.el-menu-item.is-active {
  background-color: aqua;
  border-bottom: 2px solid red;
}

.el-menu--horizontal>.el-menu-item:hover {
  background-color: pink;
}
</style>